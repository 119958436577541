import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import LinkBack from '../components/link-back'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulNewsItem')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const metaDescription = get(
      this,
      'props.data.contentfulNewsItem.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulNewsItem.metaImage.file.url'
    )
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
        <Helmet title={post.title + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={`https://cleanairdev.co.uk/news/${post.slug}`}
            />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={post.title + ' | ' + siteTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>          
          {post.heroImage && (
            <div>
              <Img
                className={'newsHeader'}
                alt={post.title}
                fluid={post.heroImage.fluid}
              />
            </div>
          )}

          <Container className="news mt-5 mb-5">
           <LinkBack to={'/news'} title={'back to main news hub'} />
            <Row>
              <Col>
                <h1>{post.title}</h1>
                <p
                  style={{
                    display: 'block',
                  }}
                >
                  {post.publishDate}
                </p>
                {post.body && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.body.childMarkdownRemark.html,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query NewsItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulNewsItem(slug: { eq: $slug }) {
      title
      metaDescription
      metaImage {
        file {
          url
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
