import React from 'react'
import { Link } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

class LinkBack extends React.Component {
  render() {
    return (
      <div className="mt-5 mb-5 link-back">
      <Link className="link-back__link" to={this.props.to}>
        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
        {this.props.title}
      </Link>
    </div>
    )
  }
}

export default LinkBack
